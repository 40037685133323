import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { combineLatest, of, switchMap } from "rxjs";
import { queryFactory } from "../state/query";
import { useMemo } from "react";

export const useQueries = (ids: string[]) => {
  const queryIds = useMemo(() => ids, [ids]);

  const queries$ = useMemo(() => {
    if (!queryIds || queryIds.length === 0) {
      return of([]);
    }

    return queryFactory
      .fromIds$(queryIds)
      .pipe(
        switchMap((queries) =>
          queries.length === 0 ? of([]) : combineLatest(queries.map((query) => query.store.value$)),
        ),
      );
  }, [queryIds]);

  const queriesData = useObservableState(queries$, []);
  return queriesData;
};

export const useQueryBySlugId = (slugId: string) => {
  const sludId$ = useObservable(pluckFirst, [slugId]);
  const bySlugId$ = useObservable(() => sludId$.pipe(switchMap((id) => queryFactory.fromSlugId$(id))));
  const query = useObservableState(bySlugId$, null);
  return query;
};
